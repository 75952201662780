import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { EncryptDecrypt  } from '../directives/encrypt_decrypt';
@Injectable()
export class AppInterceptor implements HttpInterceptor {
    constructor(private encrypt: EncryptDecrypt) {}
  
    intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // console.log(`Request for ${req.urlWithParams} started...`);

        if (localStorage.getItem('TamuApp')) {
          const TamuApp = JSON.parse(this.encrypt.Decrypt(localStorage.getItem('TamuApp')));
      if (TamuApp && TamuApp.Token) {
          req = req.clone({
              setHeaders: {
                  Token: `${TamuApp.Token}`
              }
          });
      }
  }





        return next.handle(req).pipe(map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              // console.log(`Request for ${req.urlWithParams} completed...`);
            }
            return event;
          }),
          catchError((error: HttpErrorResponse) => {
            const started = Date.now();            
            const elapsed = Date.now() - started;
            console.log(`Request for ${req.urlWithParams} failed after ${elapsed} ms.`);
           // debugger;
            return throwError(error);
          })
        );

    }  
}