<div class="logo"> 
 <img src="assets/images/others/Logo.png" style="width:180px">

</div>


 



