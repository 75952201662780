<footer>    
    <div class="px-3" style="padding-bottom: 40px;">
        <div class="theme-container"> 
           
            <div class="py-5 content border-lighter"> 
                <div fxLayout="row wrap" fxLayoutAlign="space-between"> 
                    <div fxFlex="100" fxFlex.gt-sm="30" fxFlex.sm="45"> 
                        <a class="logo" routerLink="/"><app-logo></app-logo></a>
                        <p class="mt-4 mb-3 desc">
                            Tamu Tamu Cuisine Sarl
                        </p>
                        <p fxLayout="row" fxLayoutAlign="start center" class="mt-2">
                            <mat-icon color="primary">location_on</mat-icon>
                            <span class="mx-2">Abidjan</span>
                        </p>
                        <p fxLayout="row" fxLayoutAlign="start center" class="mt-1">
                            <mat-icon color="primary">call</mat-icon>
                            <span class="mx-2">0585608514</span>
                        </p>
                        <p fxLayout="row" fxLayoutAlign="start center" class="mt-1">
                            <mat-icon color="primary">mail_outline</mat-icon>
                            <span class="mx-2">md@tamucuisine.com</span>
                        </p>
                        <p fxLayout="row" fxLayoutAlign="start center" class="mt-1">
                            <mat-icon color="primary">schedule</mat-icon>
                            <span class="mx-2">Mon - Fri / 8:00AM - 5:00PM</span>
                        </p>
                        <app-social-icons [iconSize]="'lg'" fxLayout="row" fxLayoutAlign="start center" class="desc"></app-social-icons>                    
                    </div>

              

                    <div fxFlex="100" fxFlex.gt-sm="30" fxFlex.sm="45" ngClass.xs="pt-5"> 
                        <h1 class="secondary-font">Feedback</h1>
                        <p class="desc">We want your feedback about everything</p>
                        <form [formGroup]="feedbackForm" (ngSubmit)="onFeedbackFormSubmit(feedbackForm.value)" class="custom-form pt-2">                            
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>E-mail</mat-label> 
                                <input matInput autocomplete="off" formControlName="email">
                                <mat-error *ngIf="feedbackForm.controls.email.errors?.required">Email is required</mat-error>
                                <mat-error *ngIf="feedbackForm.controls.email.hasError('invalidEmail')">Invalid email address</mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Message for us</mat-label> 
                                <textarea matInput formControlName="message" rows="6"></textarea> 
                                <mat-error *ngIf="feedbackForm.controls.message.errors?.required">Message is required</mat-error>
                            </mat-form-field>
                            <div class="w-100 text-center">
                                <button mat-flat-button color="primary" class="uppercase" type="submit">Submit</button> 
                            </div> 
                        </form> 
                    </div>
 
             
                </div>  
            </div> 
           
            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" class="copyright py-2" >
                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center">
                    <p style="margin-right: 5px;">
                        <a mat-button href="/news">News</a></p>
                    &nbsp;
                    <p  style="margin-left: 5px;">
                        <a mat-button href="/terms-conditions">Terms</a>
                    </p>  
                </div>
               
                <p>Designed & Developed by <a mat-button href="https://lucentsolutions.co.ke/" target="_blank">Lucent Solutions</a></p>
            </div> 
           
          
        </div>
    </div>      
</footer>