import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { menuItems } from '../../admin/components/menu/menu';
import { EncryptDecrypt } from '../directives/encrypt_decrypt';
@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private encrypt: EncryptDecrypt,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.getItem("TamuApp")) {
      const currentUser = JSON.parse(
        this.encrypt.Decrypt(localStorage.getItem("TamuApp"))
      );

    
      var url = state.url.split(';')[0];


  

      if (currentUser.Usergroup != 1 && url.indexOf('admin') >= 0) {
        this.router.navigate(["/"]);
        return true;

      }
      if (currentUser.Usergroup == 1 && url.indexOf('admin') == -1) {
        this.router.navigate(["/"]);
        return true;

      }

      if (currentUser.Usergroup == 1) {

        var values = currentUser.Roles.split(',');

        if (values.indexOf(menuItems.filter(x => x.routerLink == url)[0].id.toString()) == -1) {
          this.router.navigate(["/admin"]);
          return true;

        }
     


      }



      if (currentUser.PasswordChanged === false) {
        this.router.navigate(["changepassword"], {
          queryParams: { returnUrl: state.url },
        });
        return true;
      }

      //   if (currentUser.OTPRequired === true && currentUser.OTPVerified === 0 ) {
      //     this.router.navigate(["otp"], {
      //       queryParams: { returnUrl: state.url },
      //     });
      //     return true;

      //   }











      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(["login"], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
