import { Component, OnInit, Output, EventEmitter } from '@angular/core';
 
import { AppService } from 'src/app/app.service';
 
@Component({
  selector: 'app-toolbar1',
  templateUrl: './toolbar1.component.html'
})
export class Toolbar1Component implements OnInit {
  @Output() onMenuIconClick: EventEmitter<any> = new EventEmitter<any>();


  constructor(public appService: AppService) {
   

  }

  ngOnInit() {
 
    if (localStorage.getItem('TamuApp')) {
      this.appService.loggedin = true;
    }
    else { 
      this.appService.loggedin = false;

    }
  }

  public sidenavToggle() {
    this.onMenuIconClick.emit();
  }
  
}