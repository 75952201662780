import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem, Order, Category, Division } from 'src/app/app.models';
import { AppSettings } from 'src/app/app.settings';
import { environment } from 'src/environments/environment';
import { ConfirmDialogComponent, ConfirmDialogModel } from './shared/confirm-dialog/confirm-dialog.component';
import { AlertDialogComponent } from './shared/alert-dialog/alert-dialog.component';
import { map } from 'rxjs/operators';

export class Data {
  constructor(public categories: Category[],
    public divisions: Division[],
    public cartList: MenuItem[],
    public orderList: Order[],
    public favorites: MenuItem[],
    public totalPrice: number,
    public totalCartCount: number, 
    public menuslist: any, 
    public ParentStudents: any,
    public UserData: any,
    public AccountInfo: any
  ) { }
}

@Injectable({
  providedIn: 'root'
})
export class AppService {
  public Data = new Data(
    [],  // categories 
    [],  // divisions 
    [],  // cartList
    [],  // orderList
    [],  // favorites 
    0, // totalPrice
    0, //totalCartCount
    [], //menuslist,
    [], // ParentStudents
    [], //UserData
    [] //AccountInfo
  )
  public loggedin = false;
  public userfullnames ="";
  public usergroup = "0"; 
  public username = "";
  public timeZone = "Africa/Nairobi"; //Africa/Abidjan
  public url = environment.url + '/assets/data/';

  constructor(public http: HttpClient,
    private datePipe: DatePipe,
    private bottomSheet: MatBottomSheet,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    public appSettings: AppSettings,
    public translateService: TranslateService) { }

  validateToken() {
    return this.http.get(environment.apiurl + '/validateToken');
  }
  Connect() {
    return this.http.get(environment.apiurl + '/Connect');
  }
  VerifyStudentId(req: any) {
    return this.http.post(environment.apiurl + '/VerifyStudentId', req);
  }

  ParentRegister(req: any) {
    return this.http.post(environment.apiurl + '/ParentRegister', req);
  }
  ShowTodaysMenu() {
    return this.http.get(environment.apiurl + '/ShowTodaysMenu');
  }
  ShowWeeklyMenu() {
    return this.http.get(environment.apiurl + '/ShowWeeklyMenu');
  }
  ListMenuShedules(req:any) {
    return this.http.post(environment.apiurl + '/ListMenuShedules',req);
  }
  ShowCategories() {
    return this.http.get(environment.apiurl + '/ShowCategories');
  }

  ChangeFirstPassword(req: any) {
    return this.http.post(environment.apiurl + '/ChangeFirstPassword', req);
  }
  ResetPasswordLink(req: any) {
    return this.http.post(environment.apiurl + '/ResetPasswordLink', req);
  }
  VerifyResetLink(req: any) {
    return this.http.post(environment.apiurl + '/VerifyResetLink', req);
  }
  

  ParentStudents(req: any) {
    return this.http.post(environment.apiurl + '/ParentStudents', req);
  }
  
  AccountInfo(req: any) {
    return this.http.post(environment.apiurl + '/AccountInfo', req);
  }
  VerifyStudentIdUnMasked(req: any) {
    return this.http.post(environment.apiurl + '/VerifyStudentIdUnMasked', req);
  }
  ParentStudentRegister(req: any) {
    return this.http.post(environment.apiurl + '/ParentStudentRegister', req);
  }
  CategoriesList() {
    return this.http.get(environment.apiurl + '/CategoriesList');
  }
  MenuCategories(req: any) {
    return this.http.post(environment.apiurl + '/MenuCategories', req);
  }
  DeleteCategory(req: any) {
    return this.http.post(environment.apiurl + '/DeleteCategory', req);
  }

  DivisionsList() {
    return this.http.get(environment.apiurl + '/DivisionsList');
  }
  MenuDivisions(req: any) {
    return this.http.post(environment.apiurl + '/MenuDivisions', req);
  }
  DeleteDivision(req: any) {
    return this.http.post(environment.apiurl + '/DeleteDivision', req);
  }

  MenuList() {
    return this.http.get(environment.apiurl + '/MenuList');
  }
  Menus(req: any) {
    return this.http.post(environment.apiurl + '/Menus', req);
  }
  DeleteMenu(req: any) {
    return this.http.post(environment.apiurl + '/DeleteMenu', req);
  }

  getMenuById(id: any) {
    return this.http.get(environment.apiurl + '/getMenuById/' + id);
  } 
  
  LoadOrderMenuList(id: any) {
    return this.http.get(environment.apiurl + '/LoadOrderMenuList/' + id);
  } 
  
 
  UploadMenuImage(req: any) {
    return this.http.post(environment.apiurl + '/UploadMenuImage', req);
  }
  
  DeleteMenuImage(req: any) {
    return this.http.post(environment.apiurl + '/DeleteMenuImage', req);
  }
  
  getFilteredMenu(req: any) {
    return this.http.post(environment.apiurl + '/getFilteredMenu', req);
  }
  MenuSchedules(req: any) {
    return this.http.post(environment.apiurl + '/MenuSchedules', req);
  }
  DeleteMenuSchedule(req: any) {
    return this.http.post(environment.apiurl + '/DeleteMenuSchedule', req);
  }

  OrderMenu(req: any) {
    return this.http.post(environment.apiurl + '/OrderMenu', req);
  }
  ParentOrdersList(req: any) {
    return this.http.post(environment.apiurl + '/ParentOrdersList', req);
  }

  CancelOrder(req: any) {
    return this.http.post(environment.apiurl + '/CancelOrder', req);
  } 
  OrdersList(req: any) {
    return this.http.post(environment.apiurl + '/OrdersList', req);
  } 

  Grades() {
    return this.http.get(environment.apiurl + '/Grades');
  } 
  DeductionsReport(req: any) {
    return this.http.post(environment.apiurl + '/DeductionsReport', req);
  } 
  OrdersReport(req: any) {
    return this.http.post(environment.apiurl + '/OrdersReport', req);
  } 

  ChefOrdersList(req: any) {
    return this.http.post(environment.apiurl + '/ChefOrdersList', req);
  } 
  OrdersListReport(req: any) {
    return this.http.post(environment.apiurl + '/OrdersListReport', req);
  } 
  OrderMenuListByDate(req: any) {
    return this.http.post(environment.apiurl + '/OrderMenuListByDate', req);
  } 
  Parents() {
    return this.http.get(environment.apiurl + '/Parents');
  } 
  ListTeachers() {
    return this.http.get(environment.apiurl + '/ListTeachers');
  } 
  ParentStudentById(id: any) {
    return this.http.get(environment.apiurl + '/ParentStudentById/' + id);
  } 
  Discounts(req: any) {
    return this.http.post(environment.apiurl + '/Discounts', req);
  }   
  
  AddSystemSettings(req: any) {
    return this.http.post(environment.apiurl + '/AddSystemSettings', req);
  }
  ShowDiscounts() {
    return this.http.get(environment.apiurl + '/ShowDiscounts');
  } 
  ShowDiscountsByCategory(id:any) {
    return this.http.get(environment.apiurl + '/ShowDiscountsByCategory/' + id);
  } 
  DiscountList() {
    return this.http.get(environment.apiurl + '/DiscountList');
  } 
  LoadSystemSettings() {
    return this.http.get(environment.apiurl + '/LoadSystemSettings');
  } 
  DeleteDiscounts(req: any) {
    return this.http.post(environment.apiurl + '/DeleteDiscounts', req);
  } 

  DiscountMemberList(id: any) {
    return this.http.get(environment.apiurl + '/DiscountMemberList/' + id);
  } 

  SelectMemberList(id: any) {
    return this.http.get(environment.apiurl + '/SelectMemberList/' + id);
  } 

  DiscountMembers(req: any) {
    return this.http.post(environment.apiurl + '/DiscountMembers', req);
  } 
  DeleteMembers(req: any) {
    return this.http.post(environment.apiurl + '/DeleteMembers', req);
  } 
  ParentList() {
    return this.http.get(environment.apiurl + '/ParentList');
  } 
  TeacherList() {
    return this.http.get(environment.apiurl + '/TeacherList');
  } 
  Parent(req: any) {
    return this.http.post(environment.apiurl + '/Parent', req);
  } 
  Teacher(req: any) {
    return this.http.post(environment.apiurl + '/Teacher', req);
  } 
  RemoveParentStudent(req: any) {
    return this.http.post(environment.apiurl + '/RemoveParentStudent', req);
  } 
  AdminStudentRegister(req: any) {
    return this.http.post(environment.apiurl + '/AdminStudentRegister', req);
  } 
  DiscountMember(req: any) {
    return this.http.post(environment.apiurl + '/DiscountMember', req);
  } 
  ReverseAccounts(req: any) {
    return this.http.post(environment.apiurl + '/ReverseAccounts', req);
  } 
  CustomerAccounts(req: any) {
    return this.http.post(environment.apiurl + '/CustomerAccounts', req);
  }  
  CustomerAccountsList(req: any) {
    return this.http.post(environment.apiurl + '/CustomerAccountsList', req);
  } 
  CustomerAccountsListByUser(req: any) {
    return this.http.post(environment.apiurl + '/CustomerAccountsListByUser', req);
  } 
  DeductOrders(req: any) {
    return this.http.post(environment.apiurl + '/DeductOrders', req);
  } 
  AccountBalancesList(req: any) {
    return this.http.post(environment.apiurl + '/AccountBalancesList', req);
  } 
  DisableAccounts(req: any) {
    return this.http.post(environment.apiurl + '/DisableAccounts', req);
  } 
  StudentList() {
    return this.http.get(environment.apiurl + '/StudentList');
  } 
  Student(req: any) {
    return this.http.post(environment.apiurl + '/Student', req);
  } 
  StudentImport(req: any) {
    return this.http.post(environment.apiurl + '/StudentImport', req);
  } 

  users() {
    return this.http.get(environment.apiurl + '/users');
  } 
  user(req: any) {
    return this.http.post(environment.apiurl + '/users', req);
  } 
  UserRoles() {
    return this.http.get(environment.apiurl + '/UserRoles');
  } 
  ResetUserPassword(req: any) {
    return this.http.post(environment.apiurl + '/ResetUserPassword', req);
  } 
  disableuser(req: any) {
    return this.http.post(environment.apiurl + '/disableuser', req);
  } 
  UserRoleList() {
    return this.http.get(environment.apiurl + '/UserRoleList');
  } 
  UserRole(req: any) {
    return this.http.post(environment.apiurl + '/UserRole', req);
  } 
  RemoveUserRole(req: any) {
    return this.http.post(environment.apiurl + '/RemoveUserRole', req);
  } 
  UserRoleDetail(id: any) {
    return this.http.get(environment.apiurl + '/UserRoleDetail/' + id);
  } 
  RoleDetail(req: any) {
    return this.http.post(environment.apiurl + '/RoleDetail', req);
  } 
  SystemProcesses() {
    return this.http.get(environment.apiurl + '/SystemProcesses');
  } 
  EmailTemplate(id: any) {
    return this.http.get(environment.apiurl + '/EmailTemplate/' + id);
  } 
  EmailTemplates(req: any) {
    return this.http.post(environment.apiurl + '/EmailTemplates', req);
  } 
  AccountAlertDetails(req: any) {
    return this.http.post(environment.apiurl + '/AccountAlertDetails', req);
  } 
  SendBalanceAlertEmail(req: any) {
    return this.http.post(environment.apiurl + '/SendBalanceAlertEmail', req);
  } 
  OrderConfirmationEmail(req: any) {
    return this.http.post(environment.apiurl + '/OrderConfirmationEmail', req);
  } 
  StudentOrderList() {
    return this.http.get(environment.apiurl + '/StudentOrderList');
  } 
  NewsList() {
    return this.http.get(environment.apiurl + '/NewsList');
  } 
  getNewsById(id: any) {
    return this.http.get(environment.apiurl + '/getNewsById/' + id);
  } 
  News(req: any) {
    return this.http.post(environment.apiurl + '/News', req);
  } 
  DeleteNews(req: any) {
    return this.http.post(environment.apiurl + '/DeleteNews', req);
  } 

  ChangePassword(req: any) {
    return this.http.post(environment.apiurl + '/ChangeUserPassword', req);
  } 
  RefTypes() {
    return this.http.get(environment.apiurl + '/RefTypes');
  } 

  DashboardTiles() {
    return this.http.get(environment.apiurl + '/DashboardTiles');
  } 
  AccountStatus() {
    return this.http.get(environment.apiurl + '/AccountStatus');
  } 
  OrdersByStatus() {
    return this.http.get(environment.apiurl + '/OrdersByStatus');
  } 
  MonthlyOrders() {
    return this.http.get(environment.apiurl + '/MonthlyOrders');
  }

  WeeklyOrders() {
    return this.http.get(environment.apiurl + '/WeeklyOrders');
  }

  TopGrades() {
    return this.http.get(environment.apiurl + '/TopGrades');
  }

  TopMenus() {
    return this.http.get(environment.apiurl + '/TopMenus');
  }

  
  

  //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------             
 
  public openDialog(component: any, data: any, panelClass: any) {
    return this.dialog.open(component, {
      data: data,
      panelClass: panelClass,
      autoFocus: false,
      direction: (this.appSettings.settings.rtl) ? 'rtl' : 'ltr'
    });
  }

  public openConfirmDialog(title: string, message: string) {
    const dialogData = new ConfirmDialogModel(title, message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });
    return dialogRef;
  }

  public openAlertDialog(message: string) {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      maxWidth: "400px",
      data: message
    });
    return dialogRef;
  }
  
  public getTranslateValue(key: string, param: string = '') {
    let value = '';
    this.translateService.get(key, { param: param }).subscribe((res: string) => {
      value = res;
    })
    return value;
  }

  public convertImgToBase64(url:string, callback:any){
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
      var reader = new FileReader();
      reader.onloadend = function() {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }
  private mailApi = 'https://mailthis.to/codeninja'
  public PostMessage(input: any) {
    return this.http.post(this.mailApi, input, { responseType: 'text' })
      .pipe(
        map(
          (response:any) => {
            if (response) {
              return response;
            }
          },
          (error: any) => {
            return error;
          }
        )
      )
  }


}
