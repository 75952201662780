import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { EncryptDecrypt } from '../../directives/encrypt_decrypt';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {
 usergroup="0";
  constructor(public appService:AppService,
    private encrypt: EncryptDecrypt,) { }

  ngOnInit() {

    if (localStorage.getItem("TamuApp")) {
      const currentUser = JSON.parse(
        this.encrypt.Decrypt(localStorage.getItem("TamuApp"))
      );
 
      this.usergroup=currentUser.Usergroup;


    }


  }

}
