import { Menu } from './menu.model';

export const horizontalMenuItems = [ 
    new Menu (1, 'NAV.HOME', '/', null, null, false, 0),
    new Menu (2, 'Menu', '/menu', null, null, false, 0), 
    new Menu (3, 'How to register', '/howtoregister', null, null, false, 0),  
    new Menu (4, 'How to Order ', '/howtoorder', null, null, false, 0),  
    new Menu (5, 'How to Pay', '/howtopay', null, null, false, 0),  
    new Menu (6, 'NAV.ABOUT_US', '/about', null, null, false, 0),   
   
]

export const verticalMenuItems = [ 
    new Menu (1, 'NAV.HOME', '/', null, null, false, 0),
    new Menu (2, 'Menu', '/menu', null, null, false, 0), 
    new Menu (3, 'How to register', '/howtoregister', null, null, false, 0),  
    new Menu (4, 'How to Order ', '/howtoorder', null, null, false, 0),  
    new Menu (5, 'How to Pay', '/howtopay', null, null, false, 0),  
    new Menu (6, 'NAV.ABOUT_US', '/about', null, null, false, 0),  
   
]