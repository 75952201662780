import { Menu } from './menu.model'; 

export const menuItems = [ 
    new Menu (10, 'ADMIN_NAV.DASHBOARD', '/admin', null, 'dashboard', null, false, 0),
    new Menu (20, 'ADMIN_NAV.MENU_ITEMS', null, null, 'grid_on', null, true, 0),  
    new Menu (21, 'ADMIN_NAV.CATEGORIES', '/admin/menu-items/categories', null, 'category', null, false, 20), 
    new Menu (22, 'ADMIN_NAV.DIVISIONS', '/admin/menu-items/divisions', null, 'table_chart', null, false, 20), 
    new Menu (23, 'ADMIN_NAV.MENU_ITEMS_LIST', '/admin/menu-items/list', null, 'list', null, false, 20), 
    new Menu (24, 'ADMIN_NAV.ADD_MENU_ITEM', '/admin/menu-items/add', null, 'add_circle_outline', null, false, 20), 
    new Menu (25, 'ADMIN_NAV.MENU', '/admin/menu-items/menu', null, 'receipt', null, false, 20), 
    new Menu (30, 'ADMIN_NAV.SALES', null, null, 'monetization_on', null, true, 0),  
    new Menu (31, 'ADMIN_NAV.ORDERS', '/admin/sales/orders', null, 'list_alt', null, false, 30), 
    new Menu (32, 'ADMIN_NAV.REPORTS', '/admin/sales/reports', null, 'assignment', null, false, 30),  
    new Menu (33, 'ADMIN_NAV.DISCOUNTS', '/admin/sales/discounts', null, 'pie_chart', null, false, 30),  
    new Menu (34, 'ADMIN_NAV.DEDUCTIONS', '/admin/sales/deductions', null, 'description', null, false, 30),  
    new Menu (35, 'ADMIN_NAV.TEMPLATES', '/admin/sales/templates', null, 'message', null, false, 30),  
    new Menu (36, 'ADMIN_NAV.SETTINGS', '/admin/sales/settings', null, 'settings', null, false, 30), 
    new Menu (50, 'ADMIN_NAV.ACCOUNTS', null, null, 'supervisor_account', null, true, 0),  
    new Menu (51, 'ADMIN_NAV.PARENTS', '/admin/accounts/parents', null, 'person', null, false, 50),  
    new Menu (52, 'ADMIN_NAV.TEACHERS', '/admin/accounts/teachers', null, 'school', null, false, 50), 
    new Menu (52, 'ADMIN_NAV.OVERDRWAN', '/admin/accounts/balances', null, 'account_balance', null, false, 50), 
    new Menu (40, 'ADMIN_NAV.USERS', '/admin/users', null, 'group_add', null, false, 0),
    new Menu (60, 'ADMIN_NAV.STUDENTS', '/admin/students', null, 'school', null, false, 0), 
    new Menu (70, 'ADMIN_NAV.NEWS', '/admin/news', null, 'comment', null, false, 0),  
]