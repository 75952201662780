<a mat-button [matMenuTriggerFor]="userMenu" #userMenuTrigger="matMenuTrigger">
    <mat-icon class="mat-icon-sm">person</mat-icon>
    <span fxShow="false" fxShow.gt-sm class="mx-1 uppercase">{{ 'ACCOUNT' | translate }}</span> 
    <mat-icon class="mat-icon-sm caret">arrow_drop_down</mat-icon>
</a>
<mat-menu #userMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="app-menu-panel user-menu">
    <span (mouseleave)="userMenuTrigger.closeMenu()">
        <div fxLayout="row" fxLayoutAlign="start center" class="user-info">                   
            <img src="assets/images/others/user.jpg" alt="user-image" width="40">
            <p class="m-0">{{appService.userfullnames}}<br> <small><i>{{appService.username}}</i></small></p>
        </div>
        <div class="divider"></div>

        <a mat-menu-item routerLink="/account/dashboard" *ngIf="usergroup!='1'" > 
            <mat-icon class="mat-icon-sm">settings</mat-icon>
            <span>My Account</span> 
        </a>
       
        <a mat-menu-item routerLink="/admin" *ngIf="usergroup=='1'" > 
            <mat-icon class="mat-icon-sm">settings</mat-icon>
            <span>My Account</span> 
        </a>
       
        <a mat-menu-item routerLink="/faq" > 
            <mat-icon class="mat-icon-sm">help</mat-icon>
            <span>Help</span> 
        </a>
        <div class="divider"></div>
        <a mat-menu-item routerLink="/login"> 
            <mat-icon class="mat-icon-sm">power_settings_new</mat-icon>
            <span>Sign Out</span> 
        </a>
    </span>
</mat-menu>  