<mat-card class="menu-item p-0" [fxLayout]="(viewType == 'grid') ? 'column' : 'row'"
    [ngClass]="[viewType + '-item', 'column-'+column]" style="max-height: 550px; min-height: 550px;">
    <div fxLayout="column" fxLayoutAlign="space-between center">


        <h1 class="title">{{menuItem.division}}</h1>


    </div>
    <div fxLayout="column" fxLayoutAlign="space-between center" class="divider mt-1">
    </div>

    <div fxFlex="100" [fxShrink]="(viewType == 'grid') ? '3' : '2'" class="thumbnail-section">

        <div *ngIf="lazyLoad">
            <img useCache="false" [attr.data-src]="menuItem.image.medium" class="w-100 d-block swiper-lazy">
            <div class="swiper-lazy-preloader"></div>
        </div>
        <img useCache="false" *ngIf="!lazyLoad" [src]="menuItem.image.medium" class="w-100 d-block">
    </div>
    <div fxFlex="100" class="p-3">
        <div fxLayout="column" fxLayoutAlign="start stretch" class="h-100">
            <mat-card-content class="mb-0">
                <h1 class="title">{{menuItem.name}}</h1>
                <div class="mt-2">
                    <p>{{menuItem.description}}</p>
                 
                </div>
            </mat-card-content>
            <div class="sticky-footer-helper" fxFlex="grow"></div>
            <mat-card-actions class="m-0 p-0">
                <div class="features">



                    <p fxLayout="row" fxLayoutAlign="start center" class="mb-1">
                        <span fxLayout="row" fxLayoutAlign="start center">
                            <span class="mat-icon mat-icon-sm primary-color">
                                <svg viewBox="0 0 24 24">
                                    <path *ngIf="!menuItem.isVegetarian"
                                        d="M20.84 22.73L15.14 17.03C13.26 18.79 10.92 20 8 20C7.64 20 7.14 19.87 6.66 19.7L5.71 22L3.82 21.34C5.15 18.03 6.5 14.32 9.66 11.55L8.77 10.66C6.76 12.03 4.86 14.1 3.75 17.25C3.75 17.25 2 15.5 2 13.5C2 12 3.12 9.32 5.72 7.61L1.11 3L2.39 1.73C2.39 1.73 16.39 15.74 16.39 15.74L22.11 21.46L20.84 22.73M17 8C15.35 8.37 13.93 8.88 12.7 9.5L17.5 14.29C20.87 9.35 22 3 22 3C21.03 4.95 14.35 5.24 9.38 6.18L12.15 8.95C14.81 8 17 8 17 8Z" />
                                    <path *ngIf="menuItem.isVegetarian"
                                        d="M17,8C8,10 5.9,16.17 3.82,21.34L5.71,22L6.66,19.7C7.14,19.87 7.64,20 8,20C19,20 22,3 22,3C21,5 14,5.25 9,6.25C4,7.25 2,11.5 2,13.5C2,15.5 3.75,17.25 3.75,17.25C7,8 17,8 17,8Z" />
                                </svg>
                            </span>
                        </span>
                        <span class="px-1">Vegetarian:</span>
                        <span class="dots"></span>
                        <span class="fw-500">{{ (menuItem.isVegetarian) ? 'Yes':'No'}}</span>



                    </p>

                    <div fxLayout="column" class="divider  text-right">

                        <h2 class="primary-color text-right">
                            {{menuItem.price | currency:'':''}}
                        </h2>




                    </div>


                </div>



            </mat-card-actions>
        </div>
    </div>
</mat-card>