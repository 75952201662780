import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router'; 

import { PagesComponent } from './pages/pages.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { LockScreenComponent } from './pages/lock-screen/lock-screen.component';
import { AuthGuard } from 'src/app/theme/utils/auth.guard';
const routes: Routes = [
  { 
    path: '', 
    component: PagesComponent, children: [
        //{ path: '', redirectTo: '/landing', pathMatch: 'full' },
        { path: '', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
        { path: 'about', loadChildren: () => import('./pages/about/about.module').then(m => m.AboutModule) },
        { path: 'news', loadChildren: () => import('./pages/news/news.module').then(m => m.NewsModule) },
        { path: 'menu', loadChildren: () => import('./pages/menu/menu.module').then(m => m.MenuModule) },
        { path: 'account', loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule), canActivate: [AuthGuard] }, //
        { path: 'pass', loadChildren: () => import('./pages/fpass/fpass.module').then(m => m.FpassModule) },
        { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
        { path: 'register', loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule) },
        { path: 'reset', loadChildren: () => import('./pages/reset/reset.module').then(m => m.ResetModule) },
        { path: 'changepassword', loadChildren: () => import('./pages/changepassword/changepassword.module').then(m => m.ChangepasswordModule) },
        { path: 'terms-conditions', loadChildren: () => import('./pages/terms-conditions/terms-conditions.module').then(m => m.TermsConditionsModule) },
        { path: 'howtoregister', loadChildren: () => import('./pages/faqregister/faqregister.module').then(m => m.FaqregisterModule) },
        { path: 'howtoorder', loadChildren: () => import('./pages/faqorder/faqorder.module').then(m => m.FaqorderModule), canActivate: [AuthGuard] },
        { path: 'howtopay', loadChildren: () => import('./pages/faqpay/faqpay.module').then(m => m.FaqpayModule), canActivate: [AuthGuard] },
    ]
  },
  { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule), canActivate: [AuthGuard] },
  { path: 'lock-screen', component: LockScreenComponent },
  { path: '**', component: NotFoundComponent } 
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
      initialNavigation: 'enabledBlocking', // for one load page, without reload
      relativeLinkResolution: 'legacy'
      // useHash: true
    })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }