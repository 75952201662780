import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { EncryptDecrypt  } from './theme//directives/encrypt_decrypt';

@Injectable()
export class AuthService {
    constructor(private router: Router, private http: HttpClient, private encrypt: EncryptDecrypt) { }

    login(username: string, password: string) {
        const getHeaders: HttpHeaders = new HttpHeaders({
            'Content-Type': 'application/json'
          });
        return this.http.post<any>( environment.apiurl + '/Authenticate',
         { Username: username, Password: password }, {headers: getHeaders })
            .pipe(map(user => {


                // login successful if there's a jwt token in the response
                if (user.Token != null) {


                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('TamuApp', this.encrypt.Encrypt(JSON.stringify(user)));
                }

                return user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('TamuApp');
    }

}
